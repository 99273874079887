.landing-vision-container{
    width: 100%;
}
.landing-vision{
    background-color:#f7f9fb;
    width: 100%;
    height: fit-content;
    font-family: Arial, Helvetica, sans-serif;
    padding: 10px 20px 10px
}

.landing-vision-container h1{
    font-size: 49px;
    font-weight: 500;
    width: 160px;
    border-bottom: 3px solid #111;
    letter-spacing: 5px;
    line-height: 100px;
}
.vision-heading{
    width: 242px !important;
}
.vision-content{
    color: #565656;
    line-height: 35px;
    font-size: 16px;
    padding-right: 35px;
}

@media only screen and (min-width:768px){

    .landing-vision{
        padding: 10px 30px 10px;
    }
    
    .vision-heading{
        font-size: 65px;
        letter-spacing: 1px;
        line-height: 100px;
    }
    .vision-content{
        line-height: 35px;
        font-size: 19px;
        padding-right: 70px;

    }
        
}

@media only screen and (min-width:1000px){

    .landing-vision{
        padding: 10px 30px 10px;
    }
    
    .vision-heading{
        font-size: 65px;
        letter-spacing: 1px;
        line-height: 100px;
    }
    .vision-content{
        line-height: 35px;
        font-size: 19px;
    }

    .landing-vision-container{
        margin: 0 auto;
        width: 80%;
    }
        
}