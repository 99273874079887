body{
    margin: 0;
    padding: 0;
}

.q-container{
    width: 100%;
    height: fit-content;
    background-color: #111;
    padding: 50px 40px 50px 40px;
}
.q-main{
    color: white;
    font-family: helvetica;
    width: 80%;
    overflow: hidden;
}

.q-main button{
    width: 120px;
    height: 35px;
    border-radius: 10px;
    background: none;
    color: white;
    border: 1px solid #fff;
    text-transform: uppercase;
    font-size: 12px;
    margin-top: 10px;
    font-weight: 600;
}
.q-content{
    float: left;
    width: 100%;
}
.q-btn{
    float: left;
    width: 20%;
}
.q-btn a {
    text-decoration: none;
    color: #fff;
}

@media only screen and (min-width:768px){
    .q-content{
        width: 100%;
    }

    .q-main{
        padding-bottom: 10px;
    }

    .q-main button{
        margin-top:15px;
    }
}

@media only screen and (min-width:1000px){

    .q-container{
        padding: 50px 0;
    }

    .q-main{
        margin: 0 auto;
    }

    .q-content{
        width: 80%;
    }
    
    .q-btn{
        float: left;
        width: 20%;
    }
    
}