.top-img{
    width: 100%;
    height: 80vh;
    position: relative;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(/images/Arch-logo.jpg) ;
    text-transform: uppercase;
    text-align: center;
}
.top-img::before {
    content: "";
    background: rgba(0, 0, 0, 0.7);
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
  }
 .top-img h1{
    color: #fff;
    font-size: 27px;
    font-weight: 700;
    font-family: "Poppins", sans-serif;
 }
 .top-img p{
    color: #fff;
    font-size: 14px;
    font-family: "Poppins", sans-serif;
    font-style: italic;
    margin-top: 15px;
    padding-bottom: 10px;
}
.top-txt{
    width: 100%;
    text-align: center;
    position: absolute;
    bottom: 0;

}

/* -------------------------------------------------- */
@media only screen and (min-width:768px) {

    .top-img h1{
        font-size: 35px;
     }

     .top-txt{
         bottom:70px;
     }
}

/* -------------------------------------------------- */
@media only screen and (min-width:1000px) {

    .top-img{
        height: 90vh;
    }

    .top-img h1{
        font-size: 52px;
     }

     .top-txt{
         bottom:10px;
         width:80%;
         margin-left: 10%;
     }
}