.cards_item{
    background-color: aqua;
    display: inline;
    
}
.cards_link{
    background-color: red;
    overflow: hidden;
    display: inline;
    max-width: 40%;
}
.cards_info{
    background-color: royalblue;
    display: inline;
    max-width: 54%;
}